import { useState } from "react";
import Button from "../../Components/Button/index";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import { GoHome } from "react-icons/go";
import Avatar from "../../Components/Avatar/index";
import Notifications from "../../Components/Notifications";

import "./index.css";

const Navbar = () => {
  const navigate = useNavigate();
  const {
    logout,
    user,
    userBets,
    viewNotifications,
    setViewNotifications,
  }: any = useGlobalContext();
  const [menu, setMenu] = useState(false);

  return (
    <>
      {menu && (
        <div className="navbar-menu-wrapper">
          <div className="navbar-menu">
            <AiOutlineCloseCircle
              size="35"
              className="navbar-menu-close"
              onClick={() => setMenu(false)}
            />

            <ul>
              {userBets ? (
                <>
                  {" "}
                  <li
                    onClick={() => {
                      navigate("/");
                      setMenu(false);
                    }}
                  >
                    Home
                  </li>
                  <li
                    onClick={() => {
                      navigate("/create-bet");
                      setMenu(false);
                    }}
                  >
                    Create Bet
                  </li>
                  <li
                    onClick={async () => {
                      setMenu(false);
                      await logout();
                      window.location.reload();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </li>
                </>
              ) : (
                <>
                  <li
                    onClick={() => {
                      navigate("/login");
                      setMenu(false);
                    }}
                  >
                    Login
                  </li>
                  <li
                    onClick={() => {
                      navigate("/signup");
                      setMenu(false);
                    }}
                  >
                    Signup
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
      <div className="navbar">
        {user?.notifications?.length > 0 && (
          <div className="navbar-notification"></div>
        )}
        <p onClick={() => navigate("/")} className="navbar-logo">
          BETJAB
        </p>

        <div className="navbar-right-items">
          {userBets && (
            <Avatar
              size={"40px"}
              userName={user.name}
              className="navbar-avatar"
              onClick={() => setViewNotifications(!viewNotifications)}
            ></Avatar>
          )}

          <RxHamburgerMenu
            size="35"
            onClick={() => setMenu(true)}
            className="navbar-hamburger"
          />
        </div>

        {viewNotifications && user.notifications.length > 0 && (
          <Notifications
            className="navbar-notifications"
            notifications={user.notifications}
            navigate={navigate}
          />
        )}
      </div>
    </>
  );
};

export default Navbar;
