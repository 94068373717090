import React from "react";
import "./index.css";

interface Props {
  userName: string;
  imgUrl?: string;
  size?: string;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Avatar = ({
  userName,
  imgUrl,
  size,
  className,
  onClick,
  children,
}: Props) => {
  const avatarWrapperStyle = {
    height: size || "100px",
    width: size || "100px",
  };

  const avatarTextStyle = {
    fontSize: `calc(${size || "200px"} * 0.4)`,
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`avatar-wrapper ${className}`}
        style={avatarWrapperStyle}
      >
        <img
          src={!imgUrl ? "https://picsum.photos/200/300" : imgUrl}
          alt="asdfas"
          className="avatar-img"
        />
        <div className="avatar-overlay"></div>
        <span className="avatar-text" style={avatarTextStyle}>
          {userName.substring(0, 2)}
        </span>
      </div>
      {children}
    </>
  );
};

export default Avatar;
