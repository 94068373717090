import "./index.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context";

function Card({
  bet: {
    date,
    user: userName,
    betString,
    betAmt,
    comments,
    betTitle,
    childBets,
    currency,
    _id,
    winnerUserId,
    vote,
  },
}: any) {
  const { user }: any = useGlobalContext();
  const navigate = useNavigate();

  const cardBackgroundColor2 = () => {
    if (winnerUserId) return "card-winner-bkg";
    if (userName === user.name) return "card-your-bet-bkg";
    if (userName !== user.name) return "card-their-bet-bkg";
  };

  const dateOptions: object = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <div>
      <div
        onClick={() => navigate(`/bet/${_id}`)} //change this
        className={`card ${cardBackgroundColor2()}`}
      >
        {/* Info */}
        {winnerUserId && (
          <div className="bet-page-winner">
            <p>Winner Declared</p>
          </div>
        )}
        <h1>{betTitle}</h1>
        <div className="card-info">
          <h3>Info</h3>
          <p>
            {betString.length > 50 ? `${betString.slice(0, 90)}...` : betString}
          </p>
          <p>Created by: {userName}</p>
          <p>{new Date(parseInt(date)).toLocaleString("en-US", dateOptions)}</p>
          <p>
            Buy in: ${betAmt.toLocaleString()} {currency}
          </p>
        </div>

        {/* Bets */}
        {childBets.length > 0 && (
          <div className="card-info">
            <h3>Bets</h3>
            {childBets
              .slice(0, 3)
              .map(({ user, bet, date }: any, idx: number) => {
                if (idx === 2) {
                  return <p key={Math.random()}>... continued</p>;
                }
                return (
                  <div key={Math.random()}>
                    <p>{`${user}: ${bet} - ${date}`}</p>
                  </div>
                );
              })}
          </div>
        )}

        {/* Comments */}
        {comments.length > 0 && (
          <div className="card-info">
            <h3>Comments</h3>
            {comments
              .slice(0, 3)
              .map(({ user, comment, date }: any, idx: number) => {
                if (idx === 2) {
                  return <p key={date}>... continued</p>;
                }
                return (
                  <div key={date}>
                    <p>{`${user}: "${comment}"`}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;
