import "../../Utils/styles/utils.css";

interface Props {
  hasPaid: [{ user: string; count: number }];
  winnerUserId: [string];
}

const BetHasPaidAll = ({ hasPaid, winnerUserId }: Props) => {
  return (
    <>
      {winnerUserId && winnerUserId[0] !== "Nobody" && (
        <ul>
          <span className="info-title-dark">Has paid all: </span>
          {hasPaid.map((item: any, idx: number) => {
            //has paid everyone
            if (item.count === winnerUserId.length || item.count === 10000) {
              return (
                <li key={Math.random()} style={{ display: "inline" }}>
                  {` ${item.user}${hasPaid.length - 1 === idx ? "" : ","}`}
                </li>
              );
            } else {
              return <></>;
            }
          })}
        </ul>
      )}
    </>
  );
};

export default BetHasPaidAll;
