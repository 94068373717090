import { useEffect } from "react";
import Card from "../Card";
import "./index.css";
import { useGlobalContext } from "../../Context";

interface Bet {
  id: number;
  user: string;
  betTitle: string;
  betStr: string;
  date: string;
  comments: any;
  childBets: any;
}

function CardList({ maxView, view }: any) {
  const { userBets }: any = useGlobalContext();

  //Auth user
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const betFilter = userBets.filter((bet: any) => {
    if (view === "active") {
      return !bet.winnerUserId;
    } else if (view === "resolved") {
      return bet.winnerUserId;
    } else {
      return true;
    }
  });

  if (betFilter.length === 0)
    return (
      <p style={{ margin: "20px 0" }}>No {view === "all" ? "" : view} bets.</p>
    );

  return (
    <div className="card-list-wrap">
      <div className="card-list-grid">
        {betFilter.slice(0, maxView).map((bet: Bet) => {
          return (
            <div key={Math.random()}>
              <Card bet={bet} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CardList;
