import { useState, useEffect } from "react";
import "./index.css";

interface Props {
  alerts: { bool: boolean; msg: string }[];
  onDismiss: () => void;
}

const Alert = ({ alerts, onDismiss }: Props) => {
  const [disappear, setDisappear] = useState(false);

  useEffect(() => {
    const disappearTimeout = setTimeout(() => {
      setDisappear(true);
    }, 3000);

    return () => {
      clearTimeout(disappearTimeout);
    };
  }, []);

  useEffect(() => {
    if (disappear) {
      const onDismissTimeout = setTimeout(() => {
        onDismiss();
      }, 1000);

      return () => {
        clearTimeout(onDismissTimeout);
      };
    }
  }, [disappear, onDismiss]);

  return (
    <>
      {alerts.map(({ bool, msg }, index: number) => {
        const theClass = { top: `${7 + index * 80}px` };
        if (!msg) return <></>;
        return (
          <div
            key={index}
            style={theClass}
            className={`alert ${disappear ? "vanish" : ""} ${
              bool ? "alert-success" : "alert-fail"
            }`}
          >
            <p className="alert-text">{msg}</p>
          </div>
        );
      })}
    </>
  );
};

export default Alert;
