import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import Linkify from "react-linkify";
import { linkifyDecorator } from "../../Utils/linkify";

import "./index.css";

import Button from "../../Components/Button";

interface Props {
  vote: {
    user: string;
    userPickedWinner: [string];
    userPickedWinnerDescription: string;
  };
  betId: string | undefined;
  betTitle: string;
}

const VoteOnVote = ({ vote, betId, betTitle }: Props) => {
  const { getAuth, user, logout, myAxios, setLoad }: any = useGlobalContext();

  const [userPickedWinnerOnCurrentVote, setUserPickedWinnerOnCurrentVote] =
    useState("y");

  const navigate = useNavigate();

  const handleVoteOnCurrentVote = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    try {
      await getAuth();
      const resVoteOnCurr = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/vote-on-current`,
        {
          betId,
          user: user.name,
          userId: user.id,
          userPickedWinner: userPickedWinnerOnCurrentVote,
        }
      );
      if (resVoteOnCurr.data.data) {
        setLoad(false);
        console.log(resVoteOnCurr.data.data);
        const resNotify = await myAxios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
          {
            name: user.name,
            userId: user.id,
            betId,
            betTitle,
            type: "vote-on-vote",
          }
        );
        if (resNotify.data) {
          console.log("NOTIFY DID IT");
        }
      } else {
        console.log(resVoteOnCurr.data);

        // setShowVote(false);
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data);
      logout();
      navigate("/login");
    }
  };

  return (
    <>
      <div className="vote-on-vote-outside-wrapper">
        <div className="vote-on-vote-wrapper">
          <h3>Cast Your Vote!</h3>
          {
            <ul>
              {vote.user} thinks{" "}
              {vote.userPickedWinner?.map((voter: any) => (
                <li
                  className={"info-title-dark"}
                  key={Math.random()}
                  style={{ display: "inline" }}
                >
                  {" "}
                  {voter},
                </li>
              ))}{" "}
              won
            </ul>
          }{" "}
          <p>
            <span className="info-title-blue">Reason: </span>
            <Linkify componentDecorator={linkifyDecorator}>
              {vote.userPickedWinnerDescription
                ? vote.userPickedWinnerDescription
                : "None given."}
            </Linkify>
          </p>
          <p>Do you agree?</p>
          <form onSubmit={handleVoteOnCurrentVote}>
            <div className="vote-on-vote-form-item">
              <label className="vote-on-vote-label">Yay</label>
              <input
                className="vote-on-vote-radio"
                type="radio"
                value="y"
                checked={userPickedWinnerOnCurrentVote === "y"}
                onChange={(e) =>
                  setUserPickedWinnerOnCurrentVote(e.target.value)
                }
              />
            </div>
            <div className="vote-on-vote-form-item">
              <label className="vote-on-vote-label">Nay</label>
              <input
                className="vote-on-vote-radio"
                type="radio"
                value="n"
                checked={userPickedWinnerOnCurrentVote === "n"}
                onChange={(e) =>
                  setUserPickedWinnerOnCurrentVote(e.target.value)
                }
              />
            </div>

            <Button className="vote-on-vote-button" text="Submit" />
          </form>
        </div>
      </div>
    </>
  );
};

export default VoteOnVote;
