import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { NavigateFunction } from "react-router-dom";
import "../../Utils/styles/utils.css";
import "./index.css";

import Button from "../../Components/Button";

interface ChildBet {
  user: string;
  bet: string;
  date: string;
}

interface Props {
  childBets: ChildBet[];
  madeChildBet: boolean;
  betId: string | undefined;
  navigate: NavigateFunction;
  betTitle: string;
}

const BetChildBets = ({
  childBets,
  madeChildBet,
  betId,
  navigate,
  betTitle,
}: Props) => {
  const { getAuth, user, logout, refresh, setRefresh, myAxios, setLoad }: any =
    useGlobalContext();

  const [childBetText, setChildBetText] = useState("");

  const handleSubmitChildBet = async (e: any) => {
    if (!childBetText) {
      return;
    }
    setLoad(true);
    e.preventDefault();

    try {
      // await getAuth();
      const res = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/create-child-bet`,
        {
          betId: betId?.toString(),
          childBetText,
          user: user.name,
          userId: user.id,
        }
      );
      if (res.data) {
        setChildBetText("");
      }
      const resNotify = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
        {
          name: user.name,
          userId: user.id,
          betId,
          betTitle,
          childBetText,
          type: "child-bet",
        }
      );
      if (resNotify.data) {
        console.log("childbet notify DID IT");
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data);
      logout();
      navigate("/login");
    }
  };

  return (
    <>
      <div className="child-bets-wrapper">
        <h3>Bets</h3>
        {childBets.map(({ user, bet, date }: any) => {
          return (
            <div key={date}>
              <p>
                <span className="info-title-light">{`${user}: `}</span>
                {`${bet}`}
                {` - ${date}`}
              </p>
            </div>
          );
        })}
        {!madeChildBet && (
          <>
            <input
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmitChildBet(e);
                }
              }}
              onChange={(e) => setChildBetText(e.target.value)}
              type="text"
              value={childBetText}
            />
            <Button text="Bet Now!" handleClick={handleSubmitChildBet} />
          </>
        )}
      </div>
    </>
  );
};

export default BetChildBets;
