import { ResultType } from "@remix-run/router/dist/utils";
import React from "react";

import "./index.css";

interface Props {
  vote: {
    user: string;
    otherUserVotes: [{ user: string }];
    userPickedWinnerDescription: string;
    userPickedWinner: [string];
  };
  participants: [string];
}

const BetHasVotedList = ({ vote, participants }: Props) => {
  const voters = vote.otherUserVotes.map((vote: any) => {
    return vote.user;
  });

  const yetToVote = participants.filter(
    (participant) => !voters.includes(participant)
  );

  return (
    <>
      <div className="bet-has-voted-outside-wrapper">
        <div className="bet-has-voted-wrapper">
          <h3>Vote Active - initiated by {vote.user}</h3>
          <p>
            {vote.otherUserVotes.length} out of {participants.length} voted
          </p>
          <ul>
            <span className="info-title-blue">Has voted:</span>
            {voters?.map((voter: any) => (
              <li key={Math.random()} style={{ display: "inline" }}>
                {" "}
                {voter},
              </li>
            ))}
          </ul>
          <ul>
            <span className="info-title-blue">Yet to vote:</span>
            {yetToVote?.map((voter: any) => (
              <li key={Math.random()} style={{ display: "inline" }}>
                {" "}
                {voter},
              </li>
            ))}
          </ul>
          <ul>
            <span className="info-title-blue">
              {vote.userPickedWinner.length > 1
                ? "Picked winners"
                : "Picked winner"}
            </span>
            {vote.userPickedWinner?.map((winner: any) => (
              <li key={Math.random()} style={{ display: "inline" }}>
                {" "}
                {winner},
              </li>
            ))}
          </ul>
          <p>
            <span className="info-title-blue">Reason: </span>
            {vote.userPickedWinnerDescription
              ? vote.userPickedWinnerDescription
              : "None given"}
          </p>
        </div>
      </div>
    </>
  );
};

export default BetHasVotedList;
