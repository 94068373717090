import "./index.css";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import { useParams, useNavigate } from "react-router-dom";

import BetComment from "../../Components/BetComment";
import BetChildBets from "../../Components/BetChildBets";
import BetInvite from "../../Components/BetInvite";
import BetClose from "../../Components/BetClose";
import VoteInit from "../../Components/VoteInit";
import VoteOnVote from "../../Components/VoteOnVote";
import ManuallyPaidConfirm from "../../Components/ManuallyPaidConfirm";
import BetHasVotedList from "../../Components/BetHasVotedList";
import BetInfo from "../../Components/BetInfo";
import PayPalButtonComponent from "../../Components/PayPalButtonComponent";
import Button from "../../Components/Button";
import BetLostPayUp from "../../Components/BetLostPayUp";
import Avatar from "../../Components/Avatar";

const BetPage = () => {
  const { userBets, user }: any = useGlobalContext();

  const [isUserBet, setIsUserBet] = useState(false);

  const navigate = useNavigate();
  const { betId } = useParams();

  //Auth user
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const canUserViewBet = async () => {
      //checking if the user can view this bet (could do better?)
      const check = user.bets.some((bet: any) => {
        if (bet === betId) {
          setIsUserBet(true);
          return true;
        }
        return false;
      });
      if (!check) {
        setIsUserBet(false);
        navigate("/");
      }
    };

    canUserViewBet();
  }, []);

  let bet = userBets.filter((bet: any) => bet._id === betId);

  // If bet doesn't exist, send them home
  if (!bet[0]) return <></>; //

  let {
    date,
    user: userName,
    currency,
    betString,
    betAmt,
    comments,
    betTitle,
    childBets,
    participants,
    betClose,
    voteInit,
    vote,
    winnerUserId,
    hasPaid,
  } = bet[0];

  const madeChildBet = bet[0].childBets.some(
    (bet: any) => bet.user === user.name
  );

  const canInviteAndCloseBet =
    userName === user.name && !betClose ? true : false;

  const canVoteOnCurrentVote = !vote.otherUserVotes.some(
    (person: any) => user.name === person.user
  );

  const winnerOfBet = winnerUserId?.some((id: any) => user.id === id);

  const paidOneWinnerManually = hasPaid.some(
    (paidUser: any) => paidUser.user === user.name && paidUser.count !== 10000
  );

  const paidAllWinners = hasPaid.some(
    (paidUser: any) =>
      (paidUser.user === user.name && paidUser.count === winnerUserId.length) ||
      (user.name === paidUser.user && paidUser.count === 10000)
  );

  return (
    <div className="bet-page">
      <Avatar
        size={"200px"}
        userName={user.name}
        className="navbar-avatar bet-page-avatar"
        onClick={() => navigate("/")}
      />
      <h1>{betTitle}</h1>
      {/* BET CLOSED make me a component? */}
      {betClose && !winnerUserId && (
        <p style={{ color: "var(--primary-color)" }}>
          *Betting closed by {userName}
        </p>
      )}
      {/* HAS VOTED LIST & BET ACTIVE ALERT */}
      {voteInit && !winnerUserId && (
        <BetHasVotedList {...{ vote, participants }} />
      )}
      {/* VOTE ON CURRENT VOTE */}
      {voteInit && canVoteOnCurrentVote && (
        <VoteOnVote {...{ vote, betId, betTitle }} />
      )}

      {/* BetWinner make component */}
      {winnerUserId && (
        <div className="bet-page-winner">
          {vote.userPickedWinner.map((winner: any, idx: number) => {
            return (
              <p style={{ display: "inline-block", margin: "1px" }}>
                {winner}
                {vote.userPickedWinner.length - 1 === idx ? "" : ","}
              </p>
            );
          })}{" "}
          <p style={{ display: "inline" }}>won</p>
        </div>
      )}

      {/* HAVE THEY PAID YOU (WINNER)? MANUALLY */}
      {winnerOfBet && betAmt !== 0 && (
        <ManuallyPaidConfirm {...{ participants, vote, hasPaid, betId }} />
      )}

      {/* BET LOST PAY UP */}
      {winnerUserId &&
        !winnerOfBet &&
        winnerUserId[0] !== "Nobody" &&
        !paidOneWinnerManually &&
        !paidAllWinners &&
        betAmt !== 0 && <BetLostPayUp {...{ currency, betId, betAmt }} />}
      {/* BET INFO */}
      <BetInfo
        {...{
          date,
          userName,
          betAmt,
          participants,
          betString,
          currency,
          hasPaid,
          winnerUserId,
          vote,
        }}
      />
      {/* CHILD BETS */}
      {isUserBet && (
        <BetChildBets
          {...{ childBets, madeChildBet, betId, navigate, betTitle }}
        />
      )}
      {/* BET INVITE */}
      {canInviteAndCloseBet && <BetInvite {...{ betId, navigate }} />}
      {/* COMMENTS */}
      {isUserBet && <BetComment {...{ comments, betId, navigate, betTitle }} />}
      {/* CLOSING BET */}
      {canInviteAndCloseBet && participants.length > 1 && (
        <BetClose {...{ navigate, betId, betTitle }} />
      )}
      {/* VOTE INIT */}
      {betClose && !voteInit && (
        <VoteInit {...{ participants, betId, betTitle }} />
      )}
      {/* Manually paid one person must pay the other manually */}
      {winnerUserId &&
        !winnerOfBet &&
        winnerUserId[0] !== "Nobody" &&
        paidOneWinnerManually &&
        !paidAllWinners && (
          <p>you paid one person manually, pay the other one</p>
        )}
      <Button className="bet-page-home-button" nav="/" text="Home" />
    </div>
  );
};

export default BetPage;
