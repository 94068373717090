import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context";
import { BrowserView, isSafari } from "react-device-detect";
import Button from "../../Components/Button";
import Alert from "../../Components/Alert";
import "../../Utils/styles/utils.css";
import "./index.css";

const SignupPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [extra, setExtra] = useState("");
  const [spaceAlert, setSpaceAlert] = useState(false);
  const navigate = useNavigate();
  const { myAxios, setRefresh, setLoad, getAuth, setUser }: any =
    useGlobalContext();

  //Auth user
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authUser = await getAuth();
        if (authUser) {
          navigate("/");
        } else {
          setUser("");
          setLoad(false);
        }
      } catch (error) {
        setUser("");
        console.log("Signup Error", error);
        setLoad(false);
      }
    };
    checkAuth();
  }, []);

  const handleSubmit = async (e: any) => {
    if (name.includes(" ")) {
      setSpaceAlert(true);
      return;
    }
    setLoad(true);
    e.preventDefault();
    try {
      if (extra !== "Tony likes bikes 2016") {
        setLoad(false);
        return;
      }
      const res = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/signup`,
        {
          name: name.trimEnd(),
          email: email.trimEnd(),
          password,
        }
      );

      if (res.data) {
        navigate("/");
        setRefresh((prev: any) => prev + 1);
        (async () => {
          const response = await myAxios(
            `${process.env.REACT_APP_API_URL}/api/auth/email-bet-jab?name=${res.data.userName}`
          );
          console.log(response.data);
        })();
      }
    } catch (error: any) {
      setLoad(false);
      setError(error.response.data.errMsg);
    }
  };

  return (
    <div className="form">
      <BrowserView>
        <div style={{ color: "red", fontSize: "25px" }}>
          {isSafari &&
            "This application does not work in Safari, switch to Chrome or Firefox"}
        </div>
      </BrowserView>

      <h1>Signup</h1>
      <p>username</p>
      {spaceAlert && (
        <small className="space-alert">*username cannot include spaces</small>
      )}
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <p>email</p>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <p>password</p>
      <input
        type="text"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <p>secret</p>
      <input
        type="text"
        value={extra}
        onChange={(e) => setExtra(e.target.value)}
      />
      <Button text="Submit" handleClick={handleSubmit} />
      {error && <p>{error}</p>}
      <p>
        Already have an account?{" "}
        <a href={`${process.env.PUBLIC_URL}/login`}>Log in</a>.
      </p>
    </div>
  );
};

export default SignupPage;
