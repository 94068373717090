import Linkify from "react-linkify";
import { linkifyDecorator } from "../../Utils/linkify";
import BetParticipants from "../BetParticipants";
import BetHasPaidAll from "../BetHasPaidAll";
import "../../Utils/styles/utils.css";
import "./index.css";

interface Props {
  date: any;
  userName: string;
  betAmt: number;
  participants: [string];
  betString: string;
  currency: string;
  hasPaid: any;
  winnerUserId: any;
  vote: any;
}

const BetInfo = ({
  date,
  userName,
  betAmt,
  participants,
  betString,
  currency,
  hasPaid,
  winnerUserId,
  vote,
}: Props) => {
  const dateOptions: object = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <>
      <div className="bet-info-wrapper">
        <h3>Bet Info</h3>
        <p>
          <Linkify componentDecorator={linkifyDecorator}>
            <span className="info-title-dark">Description:</span> {betString}
          </Linkify>
        </p>
        {/* Maybe make winners a component? on BetPage too */}
        {winnerUserId && (
          <div>
            <p style={{ display: "inline", color: "var(--primary-color)" }}>
              Winner:{" "}
            </p>
            {vote.userPickedWinner.map((winner: any, idx: number) => {
              return (
                <p style={{ display: "inline-block", margin: "1px" }}>
                  {winner}
                  {vote.userPickedWinner.length - 1 === idx ? "" : ","}
                </p>
              );
            })}{" "}
            <Linkify componentDecorator={linkifyDecorator}>
              <p>
                <span className="info-title-dark">Reason:</span>{" "}
                {vote.userPickedWinnerDescription}
              </p>
            </Linkify>
            <p></p> {/*lazy padding*/}
          </div>
        )}
        <p>
          <span className="info-title-dark">Buy in: </span>$
          {betAmt.toLocaleString()} {currency}
        </p>
        <p>
          <span className="info-title-dark">Pot: </span>$
          {(participants.length * betAmt).toLocaleString()} {currency}
        </p>
        <p>
          <span className="info-title-dark">Created by: </span>
          {userName}
        </p>
        <p>
          <span className="info-title-dark">Date created: </span>
          {new Date(parseInt(date)).toLocaleString("en-US", dateOptions)}
        </p>
        <BetParticipants {...{ participants }} />
        <BetHasPaidAll {...{ hasPaid, winnerUserId }} />
      </div>
    </>
  );
};

export default BetInfo;
