import { useState } from "react";
import { useGlobalContext } from "../../Context/index";
import "./index.css";
import Button from "../Button/index";
import Linkify from "react-linkify";
import { linkifyDecorator } from "../../Utils/linkify";

const BetInvitations = () => {
  const { user, setRefresh, refresh, myAxios, setLoad }: any =
    useGlobalContext();

  const handleBetInvite = async (
    betId: string,
    isAccepted: boolean,
    betTitle?: string
  ) => {
    setLoad(true);
    try {
      const res = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/invite-to-bet-response`,
        {
          betId,
          userId: user.id,
          name: user.name,
          isAccepted,
        }
      );

      if (res.data) {
        console.log(res.data);
        await setRefresh(refresh + 1);
      }

      const resNotify = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
        {
          name: user.name,
          userId: user.id,
          betId,
          betTitle,
          type: "accept-invite",
        }
      );
      if (resNotify.data) {
        console.log("NOTIFY DID IT");
      }
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  return (
    <>
      {user.betInvites.map((bet: any) => {
        return (
          <div className="dash-invite-wrapper" key={bet._id}>
            <p>You've been invited to join...</p>
            <h3>Title: {bet.betTitle}</h3>
            <p>
              <Linkify componentDecorator={linkifyDecorator}>
                Info: {bet.betString}
              </Linkify>
            </p>
            <p>Buy in: ${bet.betAmt}</p>
            <ul>
              Participants:{" "}
              {bet.participants.map((person: any) => (
                <li className="dash-invite-participants">{person}, </li>
              ))}
            </ul>
            <Button
              className="dash-invite-button"
              text="Accept"
              handleClick={() => {
                handleBetInvite(bet._id, true, bet.betTitle);
              }}
            />
            <Button
              text="Decline"
              handleClick={() => handleBetInvite(bet._id, false)}
            />
          </div>
        );
      })}
    </>
  );
};

export default BetInvitations;
