import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import Button from "../../Components/Button";
import "../../Utils/styles/utils.css";

const CreateBet = () => {
  const [betTitle, setBetTitle] = useState("");
  const [description, setDescription] = useState("");
  const [betAmt, setBetAmt] = useState("");
  const [currency, setCurrency] = useState("");
  const [alert, setAlert] = useState(false);

  const { getAuth, user, logout, myAxios, setLoad, setViewBets }: any =
    useGlobalContext();

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSubmit = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    try {
      const authUser = await getAuth();
      if (!authUser) {
        logout();
        navigate("/login");
      }

      if (!currency) {
        setAlert(true);
        return;
      }

      const res = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet`,
        {
          betTitle,
          betString: description,
          betAmt,
          currency,
          date: Date.now(),
          user: user.name,
          userId: user.id,
          participants: [user.name],
        }
      );

      if (res.data.data._id) {
        window.location.href = `/bet/${res.data.data._id}`;
        setLoad(false);
        setViewBets("all");
        console.log("CreateBet, POST success", res.data);
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data.errMsg);
    }
  };

  return (
    <div className="form">
      {alert && (
        <Alert
          alerts={[{ bool: false, msg: "Please choose a currency" }]}
          onDismiss={() => setAlert(false)}
        />
      )}

      <h1>Create Bet</h1>
      <p>Bet Title</p>
      <input
        type="text"
        value={betTitle}
        onChange={(e) => setBetTitle(e.target.value)}
      />
      <p>Buy in Amount</p>
      <input
        type="number"
        value={betAmt}
        onChange={(e) => setBetAmt(e.target.value)}
      />
      <p>Currency</p>
      <select
        style={{ width: "auto" }}
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
      >
        <option value=""></option>
        <option value="CAD">CAD - Canadian Dollar</option>
        <option value="USD">USD - US Dollar</option>
      </select>
      <p>Description</p>
      <textarea
        style={{ width: "300px", height: "100px" }}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button text="Submit" handleClick={handleSubmit} />
      <Button nav="/" text="Home" />
    </div>
  );
};

export default CreateBet;
