import { useNavigate } from "react-router-dom";
import "./index.css";

interface Props {
  nav?: string;
  text: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
  className?: string;
}

const Button = ({ nav, text, handleClick, className }: Props) => {
  const navigate = useNavigate();

  if (nav) {
    return (
      <button
        className={`${className} button-prop-default`}
        onClick={() => {
          nav?.length && navigate(nav);
        }}
      >
        {text}
      </button>
    );
  } else {
    return (
      <button
        className={`${className} button-prop-default`}
        onClick={handleClick}
      >
        {text}
      </button>
    );
  }
};

export default Button;
