import React from "react";
import { useGlobalContext } from "../../Context";

import "./index.css";

import Button from "../../Components/Button";

interface Props {
  participants: [{ participant: string }];
  vote: { userPickedWinner: [string] };
  hasPaid: any;
  betId: string | undefined;
}

const ManuallyPaidConfirm = ({ participants, vote, hasPaid, betId }: Props) => {
  const { user, refresh, setRefresh, myAxios, setLoad }: any =
    useGlobalContext();

  const handleHasPaidManual = async (e: any, hasPaidUser: any) => {
    setLoad(true);
    e.preventDefault();

    try {
      // await getAuth(); //breaking firefox when it's off and creating a bet? dunno.
      const resHasPaidManual = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/has-paid-manual`,
        {
          betId,
          hasPaidUser,
          payee: user.name,
        }
      );
      if (resHasPaidManual.data) {
        setRefresh(refresh + 1);
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data, "the error!");
      // logout();
      // navigate("/login");
    }
  };

  const everyoneHasPaidMe = () => {
    if (hasPaid.length < 1) return false;
    if (hasPaid.length !== participants.length - vote.userPickedWinner.length)
      return false;

    const paidMe = hasPaid.every((person: any) =>
      person.payee.includes(user.name)
    );
    return paidMe;
  };

  console.log("everyonepaidme", everyoneHasPaidMe());

  return (
    <>
      {!everyoneHasPaidMe() && (
        <div className="manual-paid-confirm-outside-wrapper">
          <div className="manual-paid-confirm-wrapper">
            <h3>Get Paid</h3>
            <p>If they paid you, click their name to confirm.</p>
            {participants.map((participant: any) => {
              if (participant === user.name) return null;
              if (
                vote.userPickedWinner.some(
                  (winner: any) => winner === participant
                )
              ) {
                return null;
              }
              if (
                hasPaid.some((paidUser: any) => {
                  console.log(
                    "components/manuallypaidconfirm exception you do not know about, line 52"
                  );

                  if (paidUser.user === participant) {
                    return paidUser.payee.some(
                      (payee: any) => payee === user.name
                    );
                  }
                  return false;
                })
              ) {
                return null;
              }
              //has paid all with paypal
              if (
                hasPaid.some((paidUser: any) => {
                  if (paidUser.count === 10000) return true;
                  else return false;
                })
              ) {
                return null;
              } else {
                return (
                  <div style={{ display: "inline", marginRight: "5px" }}>
                    <Button
                      text={participant}
                      handleClick={(e: any) =>
                        handleHasPaidManual(e, participant)
                      }
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ManuallyPaidConfirm;
