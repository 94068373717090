import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { NavigateFunction } from "react-router-dom";
import "./index.css";

import Button from "../../Components/Button";
import Alert from "../../Components/Alert";

interface Props {
  betId: string | undefined;
  navigate: NavigateFunction;
}

const BetInvite = ({ betId, navigate }: Props) => {
  const { getAuth, logout, refresh, setRefresh, myAxios, setLoad }: any =
    useGlobalContext();

  const emptyAlert = [{ bool: false, msg: "" }];

  const [inviteUser, setInviteUser] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alert, setAlert] = useState(emptyAlert);

  const handleSubmitInvite = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    // if (user.name === inviteUser) return; //add error here "you're inviting yourself numnuts"

    try {
      await getAuth();
      const resInvite = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/invite-to-bet`,
        {
          name: inviteUser,
          betId,
        }
      );
      if (resInvite.data) {
        setIsAlertVisible(true);

        if (resInvite.data.data === true) {
          setAlert([{ bool: true, msg: resInvite.data.msg }]);
        }

        if (!resInvite.data.data) {
          setAlert([{ bool: false, msg: resInvite.data.msg }]);
        }

        if (resInvite.data.msg === "multi invites") {
          setAlert(resInvite.data.data);
        }

        setInviteUser("");
        setRefresh(refresh + 1);
      }
    } catch (error: any) {
      setLoad(false);
      console.log("error data", error.response.data);
      logout();
      navigate("/login");
    }
  };

  return (
    <>
      <div className="bet-invite-wrapper">
        {isAlertVisible && (
          <Alert alerts={alert} onDismiss={() => setIsAlertVisible(false)} />
        )}
        <h3>Invite</h3>
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              inviteUser && handleSubmitInvite(e);
            }
          }}
          type="text"
          value={inviteUser}
          onChange={(e) => {
            setInviteUser(e.target.value);
            setAlert(emptyAlert);
          }}
        />
        <Button
          text="Invite"
          handleClick={(e) => inviteUser && handleSubmitInvite(e)}
        />
      </div>
    </>
  );
};

export default BetInvite;
