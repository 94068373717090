import React from "react";
import "../../Utils/styles/utils.css";

interface Props {
  participants: [string];
}

const BetParticipants = ({ participants }: Props) => {
  return (
    <>
      <ul>
        <span className="info-title-dark">Participants: </span>
        {participants.map((person: string, idx: number) => (
          <li key={person} style={{ display: "inline" }}>{` ${person}${
            participants.length - 1 === idx ? "" : ","
          } `}</li>
        ))}
      </ul>
    </>
  );
};

export default BetParticipants;
