import React from "react";
import PayPalButtonComponent from "../../Components/PayPalButtonComponent";
import "./index.css";

interface Props {
  currency: string;
  betAmt: number;
  betId: string | undefined;
}

const BetLostPayUp = ({ currency, betAmt, betId }: Props) => {
  return (
    <>
      <div className="bet-lost-outside-wrapper">
        <div className="bet-lost-wrapper">
          <h3>YOU LOST!</h3>
          <img
            className="bet-lost-image"
            src="https://i.kym-cdn.com/entries/icons/mobile/000/016/729/large.jpg"
            alt=""
          />
          <p>Pay up buddy!</p>
          <p>
            *If you paid manually the winners must confirm from their account.
          </p>
          <p>*Paypal is not rigged up yet. Just shown for testing purposes.</p>

          <PayPalButtonComponent
            currency={currency}
            amount={betAmt}
            betId={betId}
          />
        </div>
      </div>
    </>
  );
};

export default BetLostPayUp;
