import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context";
import { BrowserView, isSafari } from "react-device-detect";
import Button from "../../Components/Button";
import "../../Utils/styles/utils.css";
import "./index.css";

const LoginPage = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const {
    getAuth,
    setUser,
    getUserBets,
    setUserBets,
    myAxios,
    setLoad,
    load,
    setRefresh,
  }: any = useGlobalContext();

  //Auth user (make this less clunky?)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authUser = await getAuth();
        if (authUser) {
          navigate("/");
        } else {
          setUser("");
          setLoad(false);
        }
      } catch (error) {
        setUser("");
        console.log("Login Error", error);
        setLoad(false);
      }
    };
    checkAuth();
  }, []);

  const handleSubmit = async (e: any) => {
    setLoad(true);
    e.preventDefault();
    try {
      const res = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          email,
          password,
        }
      );

      if (res.data) {
        // const authUser = await getAuth();
        // await setUser(authUser.data);
        // const betsData = await getUserBets(authUser.data.id);
        // await setUserBets(betsData);
        navigate("/");
        setRefresh((prev: any) => prev + 1);
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data.error);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="form">
      <BrowserView>
        <div style={{ color: "red", fontSize: "25px" }}>
          {isSafari &&
            "This application does not work in Safari, switch to Chrome or Firefox"}
        </div>
      </BrowserView>
      <h1>Login</h1>
      <p>email</p>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <p>password</p>
      <input
        type="text"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && <p>{error}</p>}
      <Button text="Submit" handleClick={handleSubmit} />
      <p>
        Don't have an account?{" "}
        <a href={`${process.env.PUBLIC_URL}/signup`}>Sign up</a>.
      </p>
    </div>
  );
};

export default LoginPage;
