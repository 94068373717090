//rafce
import { useEffect } from "react";
import { useGlobalContext } from "./Context";

import { Routes, Route, useNavigate } from "react-router-dom";

import SignupPage from "./Pages/SignupPage";
import CreateBet from "./Pages/CreateBet";
import BetPage from "./Pages/BetPage";
import LoginPage from "./Pages/LoginPage";
import NotFound from "./Pages/NotFound";
import Dashboard from "./Pages/Dashboard/";
import NavBar from "./Layouts/NavBar";

import "./index.css";
import "./Utils/styles/utils.css";

function App() {
  const navigate = useNavigate();

  const {
    logout,
    getAuth,
    setUser,
    setUserBets,
    getUserBets,
    userBets,
    refresh,
    setRefresh,
    user,
    load,
    setLoad,
    bodyLoad,
    setBodyLoad,
  }: any = useGlobalContext();

  //Check Auth (the next line is to prevent this warning coming up at build time)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.title =
      process.env.NODE_ENV === "production" ? "Bet Jab" : "Local Jab";

    const checkAuth = async () => {
      try {
        setBodyLoad(true);
        const authUser = await getAuth();
        const bets = await getUserBets(authUser.data.id);
        setLoad(false);
        setBodyLoad(false);
        setUser(authUser.data);
        setUserBets(bets);
      } catch (error) {
        setBodyLoad(false);
        setUser({ name: "default" });
        setUserBets();
        if (!window.location.href.includes("/signup")) navigate("/login"); //bandaid
        logout();
      }
    };
    checkAuth();
  }, [refresh]);

  bodyLoad
    ? document.body.classList.add("body-load")
    : document.body.classList.remove("body-load");

  function isMobileDevice() {
    const regex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  //Mongo Events connection
  useEffect(() => {
    let eventSource: any;

    const connectSSE = () => {
      eventSource = new EventSource(
        `${
          process.env.REACT_APP_API_URL
        }/api/events?userName=${encodeURIComponent(user.name)}`
      );

      eventSource.onmessage = (event: any) => {
        const parsedData = JSON.parse(event.data);
        console.log(parsedData);
        setRefresh((prevRefresh: any) => prevRefresh + 1);
      };

      eventSource.onerror = (error: any) => {
        console.error("EventSource failed:", error);
        if (eventSource.readyState === EventSource.CLOSED) {
          setTimeout(reconnect, 3000);
        }
      };
    };

    const reconnect = () => {
      if (eventSource) {
        eventSource.close();
      }
      connectSSE();
    };

    connectSSE();

    const handleVisibilityChange = () => {
      if (isMobileDevice() && document.visibilityState === "visible") {
        if (eventSource.readyState === EventSource.CLOSED) {
          reconnect();
          setRefresh((prevRefresh: any) => prevRefresh + 1);
        } else {
          setRefresh((prevRefresh: any) => prevRefresh + 1);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      eventSource.close();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [user.name]);

  // console.log("user App.js", user.notifications[0]);
  // console.log("userBets App.js", userBets);

  return (
    <>
      {/* Loader (make component) */}
      {load && (
        <div className="loader">
          <img
            src={`${process.env.PUBLIC_URL}/loader-dave.gif`}
            alt="loading"
          />
          <p>Loading</p>
        </div>
      )}
      <div
        style={{ maxWidth: "1600px", margin: "20px auto", padding: "0 20px" }}
      >
        <NavBar />
        <Routes>
          {/* Dashboard */}
          <Route path="/" element={userBets && <Dashboard />} />
          {/* Login Sign Up */}
          <Route path="signup" element={<SignupPage />} />
          <Route path="login" element={<LoginPage />} />
          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />}></Route>
          {/* Create Bet */}
          <Route path="create-bet" element={userBets && <CreateBet />} />
          {/* Bet Page */}
          <Route path="bet/:betId" element={userBets && <BetPage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
