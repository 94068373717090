import { useState, useEffect } from "react";
import { useGlobalContext } from "../../Context";
import { NavigateFunction } from "react-router-dom";
import Linkify from "react-linkify";
import { linkifyDecorator } from "../../Utils/linkify";
import "../../Utils/styles/utils.css";
import "./index.css";

import Button from "../../Components/Button";

interface Comment {
  user: string;
  date: string;
  comment: string;
}

interface Props {
  comments: Comment[];
  betId: string | undefined;
  betTitle: string;
  navigate: NavigateFunction;
}

const BetComment = ({ comments, betId, betTitle }: Props) => {
  const { user, myAxios, setLoad }: any = useGlobalContext();

  const [userComment, setUserComment] = useState("");
  const [viewAllComments, setViewAllComments] = useState(false);

  const commentReducedLength = 5;
  let amt = viewAllComments ? comments.length : commentReducedLength;

  useEffect(() => {
    if (window.location.hash === "#bet-comments") {
      const element = document.getElementById("bet-comments");
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSubmitComment = async (e: any) => {
    if (!userComment) {
      return;
    }
    setLoad(true);
    e.preventDefault();

    try {
      // await getAuth(); don't need this on the others?
      const resComment = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/comment-bet`,
        {
          name: user.name,
          userId: user.id,
          comment: userComment,
          betId,
        }
      );
      if (resComment.data) {
        setLoad(false);
        setUserComment("");
      }
      const resNotify = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
        {
          name: user.name,
          userId: user.id,
          comment: userComment,
          betId,
          betTitle,
          type: "comment",
        }
      );
      if (resNotify.data) {
        console.log("NOTIFY DID IT");
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data);
      // logout();
      // navigate("/login");
    }
  };

  return (
    <>
      <div id="bet-comments" className="bet-comments-wrapper">
        <h3>Comments</h3>
        {comments
          .slice(0, amt)
          .map(({ user, date, comment }: any, idx: number) => {
            return (
              <div key={Math.random()}>
                <p>
                  <span className="info-title-blue">{`${user}: `}</span>
                  <Linkify componentDecorator={linkifyDecorator}>
                    {comment}
                  </Linkify>{" "}
                  {` - ${date}`}
                </p>
              </div>
            );
          })}
        {/* {comments.length > 5 && !viewAllComments ? <p>...</p> : ""} */}
        <input
          onChange={(e) => {
            setUserComment(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmitComment(e);
            }
          }}
          type="text"
          value={userComment}
        />
        <Button text="Add Comment" handleClick={handleSubmitComment} />
        {comments.length > commentReducedLength && (
          <Button
            text={viewAllComments ? "Collapse Comments" : "View All Comments"}
            handleClick={() => setViewAllComments(!viewAllComments)}
          />
        )}
      </div>
    </>
  );
};

export default BetComment;
