import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useGlobalContext } from "../../Context";
import axios from "axios";

import "./index.css";

interface Props {
  amount: number;
  currency: string;
  betId: string | undefined;
  // handlePayPalSuccess: (data: any, actions: any) => Promise<void>;
  // onError: (err: any) => void; //may not need this
}

const PayPalButtonComponent = ({ amount, currency, betId }: Props) => {
  const { myAxios, user, refresh, setRefresh }: any = useGlobalContext();
  let id =
    "AWYB7_ER-U9-mF955vvO6g4HmXmMZMbt0L0nR1av4CC32dv-j2QuKEDEHY_k17KlGtpmBK2IOIruUD3W";

  //Mine
  const handlePayPalSuccess = async (data: any, actions: any) => {
    try {
      const response = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/success`,
        {
          orderID: data.id,
          purchaseUnits: data.purchase_units[0].amount.value,
          winnerEmail: "sb-ujsnb25574472@personal.example.com",
          currency,

          //user name so they know who paid them
          //bet info maybe so they know which bet they won
          //winners.length so I can split the pot
          //currency
        }
      );

      if (response) {
        //
        try {
          let link = response.data.links[0].href;
          const token = await getPaypalAccessToken();
          //check status wait 5 seconds, this should be on backend
          setTimeout(async () => {
            const response: any = await axios.get(link, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.data}`,
              },
            });
            const status = response.data.batch_header.batch_status;
            //Do something here maybe send it to 'HAS PAID'
            if (status === "SUCCESS" || status === "PENDING") {
              const response = await myAxios.post(
                `${process.env.REACT_APP_API_URL}/api/bet/has-paid-all-paypal`,
                {
                  hasPaidUser: user.name,
                  betId,
                }
              );
              if (response.data) {
                setRefresh(refresh + 1);
              } else {
                //something
              }
            } else {
              console.log("Payment Failed", status);
            }
          }, 5000);
        } catch (error) {
          console.log("handlepaypalsuccesss error 2", error);
        }
      } else {
        console.log("No response: handlePaypalSuccess");
      }
    } catch (error) {
      console.log("handlepaypalsuccess frontend error 1", error);
    }
  };

  const getPaypalAccessToken = async () => {
    try {
      const response = await myAxios.get(
        `${process.env.REACT_APP_API_URL}/api/payment/token`
      );
      return response;
    } catch (error) {
      console.log("getPaypalAccessToken Error", error);
    }
  };

  // const handlePayPalError = (err: any) => {
  //   // Handle errors to display to client on my app
  // };

  //Paypals'
  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      intent: "CAPTURE",
      payer: {
        payment_method: "paypal",
      },
      purchase_units: [
        {
          amount: {
            // currency_code: betCurrency need to create on Bet
            value: amount,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const handleApprove = async (data: any, actions: any) => {
    return actions.order.capture().then((details: any) => {
      console.log("handleApprove", details);

      handlePayPalSuccess(details, actions);
    });
  };

  const handleError = async (err: any) => {
    //display err message to user
    console.log("Payment Error", err);
  };

  return (
    <>
      <PayPalScriptProvider
        options={{
          "client-id": id,
          currency,
          intent: "capture",
          commit: true,
          vault: true,
          debug: false,
          components: "buttons",
        }}
      >
        <PayPalButtons
          createOrder={createOrder}
          onApprove={handleApprove}
          onError={handleError} //this auto triggers any time there's an error in paypal
          fundingSource="paypal"
          className="paypal-button"
          style={{ shape: "rect", color: "blue", label: "pay" }}
        />
      </PayPalScriptProvider>
    </>
  );
};

export default PayPalButtonComponent;
