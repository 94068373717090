import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";

import "./index.css";

import Button from "../../Components/Button";

interface Props {
  participants: [{ user: string }];
  betId: string | undefined;
  betTitle: string;
}

const VoteInit = ({ participants, betId, betTitle }: Props) => {
  const { getAuth, logout, user, myAxios, setLoad }: any = useGlobalContext();

  const navigate = useNavigate();

  const [showVote, setShowVote] = useState(false);
  const [userPickedWinner, setUserPickedWinner] = useState<string[]>([]);
  const [userPickedWinnerDescription, setUserPickedWinnerDescription] =
    useState("");

  const participantsPlusNobody = [...participants, "Nobody"];

  const handleVoteInit = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    try {
      await getAuth();
      const resVoteInit = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/vote-init`,
        {
          betId,
          user: user.name,
          userId: user.id,
          userPickedWinner,
          userPickedWinnerDescription,
        }
      );
      if (resVoteInit.data.data) {
        setLoad(false);
        setShowVote(false);

        const resNotify = await myAxios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
          {
            name: user.name,
            userId: user.id,
            betId,
            betTitle,
            type: "vote-init",
          }
        );

        if (resNotify.data) {
          console.log("NOTIFY DID IT");
        }
      } else {
        setShowVote(false);
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data);
      logout();
      navigate("/login");
    }
  };
  return (
    <>
      <div className={!showVote ? "" : "vote-init-wrapper"}>
        <Button
          className="vote-init-button"
          text={!showVote ? "Vote" : "Close Vote Window"}
          handleClick={() => setShowVote(!showVote)}
        />
        {showVote && (
          <>
            <form className="vote-init-form-wrapper" onSubmit={handleVoteInit}>
              <h3>Who won?</h3>
              <p>* Multiple winners can be chosen</p>
              {participantsPlusNobody.map((user: any) => {
                return (
                  <div className="vote-init-item-wrapper">
                    <label className="vote-init-label">{user}</label>
                    <input
                      className="vote-init-checkbox"
                      type="checkbox"
                      value={user}
                      onChange={(e) => {
                        const pickedName = e.target.value;
                        const isChecked = e.target.checked;
                        if (isChecked)
                          setUserPickedWinner((prevState) => [
                            ...prevState,
                            pickedName,
                          ]);
                        else
                          setUserPickedWinner((prevState) =>
                            prevState.filter((name) => name !== pickedName)
                          );
                      }}
                    />
                  </div>
                );
              })}
              <label className="vote-init-label">Reason</label>
              <input
                className="vote-init-reason-input"
                value={userPickedWinnerDescription}
                onChange={(e) => setUserPickedWinnerDescription(e.target.value)}
                type="text"
              />
              <Button text="Submit" className="vote-init-submit-button" />
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default VoteInit;
