import { createContext, useContext, useState } from "react";
import axios from "axios";

const AppContext = createContext({});

const AppProvider = ({ children }: any) => {
  const [user, setUser] = useState({ name: "default" });
  const [userBets, setUserBets] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [load, setLoad] = useState(false);
  const [bodyLoad, setBodyLoad] = useState(false);
  const [viewBets, setViewBets] = useState("active");
  const [viewNotifications, setViewNotifications] = useState(false);

  //Auth User
  const getAuth = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/auth/check-auth-user`,
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(res.data, "getAuth");
    return res.data;
  };

  //Grab All User Bets
  const getUserBets = async (userId: any) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/bet/get-bets-all/${userId}`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Axios response", res);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  //logout
  const logout = async () => {
    setUser({ name: "default" });
    await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  //axios instance
  const myAxios = axios.create({
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return (
    <AppContext.Provider
      value={{
        getAuth,
        logout,
        user,
        setUser,
        userBets,
        setUserBets,
        getUserBets,
        refresh,
        setRefresh,
        myAxios,
        load,
        setLoad,
        bodyLoad,
        setBodyLoad,
        viewBets,
        setViewBets,
        viewNotifications,
        setViewNotifications,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useGlobalContext };
