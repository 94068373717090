import { useState } from "react";
import { useGlobalContext } from "../../Context/index";
import { useNavigate } from "react-router-dom";

import CardList from "../../Components/CardList";
import BetInvitations from "../../Components/BetInvitations";
import Button from "../../Components/Button";
import "./index.css";

// import Stripe from "../../Components/Stripe";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51MqH0dGPcA0WvexpaG0K1jnFma3zeepabQJ0J7Vh34f1vnIspW3p8aqh2GEAwhUpTwmx3CWRXba6rGBAcYaftsuO00mPQsHI7q"
// );

function Dashboard() {
  const { userBets, viewBets, setViewBets }: any = useGlobalContext();
  const navigate = useNavigate();

  return (
    <>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        This looks like crap for now, styling & menu in the works...
      </p>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <Button
          text="Active"
          handleClick={() => setViewBets("active")}
          className={viewBets === "active" ? "dashboard-sort-button" : ""}
        />
        <Button
          text="Resolved"
          handleClick={() => setViewBets("resolved")}
          className={viewBets === "resolved" ? "dashboard-sort-button" : ""}
        />
        <Button
          text="All"
          handleClick={() => setViewBets("all")}
          className={viewBets === "all" ? "dashboard-sort-button" : ""}
        />
      </div>
      <div>
        <BetInvitations />
        {userBets && <CardList maxView={100} view={viewBets} />}
        {userBets.length === 0 && (
          <>
            <Button
              handleClick={() => navigate("/create-bet")}
              text="Get Started"
            />
          </>
        )}

        {/* <Elements stripe={stripePromise}>
        <Stripe />
      </Elements> */}
      </div>
    </>
  );
}

export default Dashboard;
