import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import { useGlobalContext } from "../../Context";
import Button from "../Button";
import "./index.css";

interface Props {
  navigate: NavigateFunction;
  notifications: [object];
  className: string;
}

const Notifications = ({ navigate, notifications, className }: Props) => {
  const { user, myAxios, setViewNotifications }: any = useGlobalContext();

  const handleDeleteNotification = async (id: any) => {
    // setViewNotifications(false);
    try {
      const resDeleteNotification = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/delete-notification`,
        {
          userId: user.id,
          notificationId: id,
        }
      );
      if (resDeleteNotification.data) {
        console.log("success delete notify");
      }
    } catch (error) {
      console.log("delete notify error", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const notifyElement = document.querySelector(".navbar-notifications");

      if (notifyElement && !notifyElement.contains(event.target as Node)) {
        setViewNotifications(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={className}>
      <div className="notifications-wrapper">
        {notifications &&
          notifications.map((notification: any) => {
            let text;
            if (notification.type === "child-bet")
              text = `${notification.info.name} bet on ${notification.info.betTitle}: "${notification.info.childBetText}"`;
            if (notification.type === "comment")
              text = `${notification.info.name} commented on ${notification.info.betTitle}: "${notification.info.comment}"`;
            if (notification.type === "accept-invite")
              text = `${notification.info.name} joined ${notification.info.betTitle}`;
            if (notification.type === "close-bet")
              text = `${notification.info.name} closed all betting on ${notification.info.betTitle}`;
            if (notification.type === "vote-init")
              text = `${notification.info.name} called for a vote on ${notification.info.betTitle}`;
            if (notification.type === "vote-on-vote")
              text = `${notification.info.name} voted on ${notification.info.betTitle}`;
            return (
              <>
                <div className="notifications-notification">
                  <p
                    onClick={() => {
                      navigate(`/bet/${notification.info.betId}`); //make this dynamic '/#bet-comments'
                      handleDeleteNotification(notification._id);
                      setViewNotifications(false);
                    }}
                  >
                    {text}
                  </p>
                </div>
              </>
            );
          })}
        <Button
          text="Clear All"
          handleClick={() => handleDeleteNotification("all")}
        />
      </div>
    </div>
  );
};

export default Notifications;
