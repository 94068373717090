import { useGlobalContext } from "../../Context";
import { NavigateFunction } from "react-router-dom";
import "./index.css";

import Button from "../../Components/Button";

interface Props {
  navigate: NavigateFunction;
  betId: string | undefined;
  betTitle: string;
}

const BetClose = ({ navigate, betId, betTitle }: Props) => {
  const { getAuth, logout, refresh, setRefresh, myAxios, setLoad, user }: any =
    useGlobalContext();

  const handleCloseBet = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    try {
      await getAuth();
      const resCloseBet = await myAxios.post(
        `${process.env.REACT_APP_API_URL}/api/bet/close-bet`,
        {
          betId,
        }
      );
      if (resCloseBet.data) {
        setLoad(false);
      }
      if (resCloseBet.data.data) {
        const resNotify = await myAxios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/notifications`,
          {
            name: user.name,
            userId: user.id,
            betId,
            betTitle,
            type: "close-bet",
          }
        );
        if (resNotify.data) {
          console.log("NOTIFY DID IT");
        }
      }
    } catch (error: any) {
      setLoad(false);
      console.log(error.response.data, "the error!");
      logout();
      navigate("/login");
    }
  };
  return (
    <>
      <Button
        className="bet-close-button"
        text="Close Bet"
        handleClick={handleCloseBet}
      />
    </>
  );
};

export default BetClose;
